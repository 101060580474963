import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"
import NoAccess from "../components/user/noaccess"

//import "../styles/mystyles.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Shakespeare In Bits" />
     <NoAccess/>
  </Layout>
)

export default NotFoundPage
