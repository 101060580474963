import React from "react"
//import "../../styles/mystyles.scss"
import { Link } from "gatsby"

const NoAccess = () => {
  return (
    <section className="hero is-primary is-medium has-text-left">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-half">
              {/* Headline */}
              <h1 className="title">
                Oops... you don't have access to this resource!
              </h1>
              <h5 className="title is-5">
                Please log in or create an account with us to continue
              </h5>
               {/* Call to action button */}
              <Link to={"/login"}>
                <button
                  className="button is-white is-outlined is-rounded"
                  rel="noopener noreferrer"
                  id="btn-spaced"
                >
                  {/* Call to action button icon */}
                  <span>Continue</span>
                  {/*<span class="icon">
                    <i class="fab fa-github fa-sm"></i>
                  </span>
                  */}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NoAccess
